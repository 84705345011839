import React, { useContext, useEffect } from 'react';
import {
  StepChecker,
  DocumentTitle,
  Typography,
  LinkNavigation,
  Accordion,
  ButtonSecondary,
  Checkbox,
  Divider,
  ButtonPrimary,
} from '../../components/atoms';
import { Grid, Box, FormControl } from '@material-ui/core';
import PageTemplate from '../../templates/PageTemplate';
import { useStyles } from './ImportantInfoStyles';
import { StepContext, steps, Step, IStepData } from '../../contexts/StepContext';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import importantInfoSchema from './ImportantSchema';
import GetAppIcon from '@material-ui/icons/GetApp';
import * as yup from 'yup';
import clsx from 'clsx';

export const ImportantInfo: React.FC = () => {
  const { activeStep, updateActiveStep, data, updateData } = useContext(StepContext);
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    updateActiveStep(3);
  }, []);

  const schema = yup.object().shape({
    meetsVehicleCriteria: yup.bool().oneOf([true], 'Checkbox must be checked to proceed'),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    trigger,
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      meetsVehicleCriteria: false,
    },
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = (stepData: IStepData) => {
    updateActiveStep(activeStep + 1);
    updateData({
      ...data,
      ...stepData,
    });
    history.push(steps[Step.PAYMENT].url);
  };

  const handleBack = async () => {
    updateActiveStep(activeStep - 1);
    updateData({
      ...data,
    });
    history.push(steps[Step.PERSONAL].url);
  };

  const getDocument = (filePath: string) => window.open(filePath);

  const { rightToCancel, complaints, financialCompensation, informationUsage, englishLawApplies } = importantInfoSchema;

  return (
    <PageTemplate>
      <StepChecker />
      <DocumentTitle title={`DLG ${process.env.REACT_APP_SITE_ID?.toUpperCase()} - Review`} />
      <Grid container className={classes.gridMainContainer}>
        <Grid item xs={12} lg={12} className={classes.gridMain}>
          <form noValidate autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
            <LinkNavigation onClick={handleBack}>Back</LinkNavigation>
            <Typography className="pt2" variant="h1">
              All the important stuff.
            </Typography>
            <Typography className="pt3" variant="h2">
              Important statements
            </Typography>
            <Typography className={clsx(classes.reponsiveWidth, 'pt2')}>
              Please make sure all the information you have told us is correct and your vehicle meets the criteria
              below. Your price is worked out using this information and if anything you’ve told us is wrong, you might
              not be covered.
            </Typography>
            <Typography className="pt2">Your vehicle(s) must meet these criteria:</Typography>
            
            <Box className={clsx(classes.reponsiveWidth, classes.importantStatementsList)}>
              <ol className="pl1">
                <li className="pl1">
                  <Typography>
                    The vehicle is a car, light van, motorhome or motorbike and is privately registered in the UK.
                  </Typography>
                </li>
                <li className="pl1">
                  <Typography>
                    The vehicle must be no more than 7 metres in length (apart from a tow bar or coupling device), 3 metres tall, 
                    2.55 metres wide and weigh no more than 3,500 metric tons (3,500kg) in total, including any load being carried.
                  </Typography>
                </li>
                <li className="pl1">
                  <Typography>
                    The vehicle meets any legal requirements and driving laws that apply including having valid tax, insurance and a MOT.
                  </Typography>
                </li>
                <li className="pl1">
                  <Typography>
                    If you&apos;re towing a caravan or trailer it will be covered under Sections 2 and 4 if the appropriate extra Premium has been 
                    paid, provided:
                  </Typography>
                  <ul className="pl1">
                    <li className="pl1">
                      <Typography>It fits a standard 50mm tow ball; and</Typography>
                    </li>
                    <li className="pl1">
                      <Typography>
                        It does not exceed (including any load carried) the following gross vehicle weight: 3.5 tonnes
                        and external dimensions: 7 metres in length (excluding any coupling device and tow bar), 3
                        metres in height and 2.3 metres in width; and
                      </Typography>
                    </li>
                    <li className="pl1">
                      <Typography>
                        The weight of the caravan or trailer when loaded is not more than the kerb weight of the Insured
                        Vehicle; and
                      </Typography>
                    </li>
                    <li className="pl1">
                      <Typography>It is of standard make; and</Typography>
                    </li>
                    <li className="pl1">
                      <Typography>All caravans and trailers carry a serviceable spare wheel and tyre.</Typography>
                    </li>
                  </ul>
                </li>
              </ol>
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <Accordion heading={rightToCancel.heading} body={rightToCancel.body} />
              <Accordion heading={complaints.heading} body={complaints.body} />
              <Accordion heading={financialCompensation.heading} body={financialCompensation.body} />
              <Accordion heading={informationUsage.heading} body={informationUsage.body} />
              <Accordion heading={englishLawApplies.heading} body={englishLawApplies.body} />
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt1')}>
              <Typography variant="h3">Insurance product information document (IPID)</Typography>
              <Typography className="py2">
                This is a summary of the main things that your policy will and won&apos;t cover. Please note it&apos;s not personalised to you and doesn&apos;t 
                contain everything.
              </Typography>
              <ButtonSecondary
                onClick={() => getDocument('pdfs/European-IPID.pdf')}
                endIcon={<GetAppIcon fontSize="large" />}
              >
                Download PDF (3.2MB)
              </ButtonSecondary>
              <Typography className="pt2">
                This product meets the demands and needs of those who wish to ensure that they have assistance if their
                vehicle breaks down and they are not in the UK.
              </Typography>
            </Box>
            <Box className={clsx(classes.reponsiveWidth, 'pt2')}>
              <FormControl component="fieldset">
                <Checkbox
                  name="meetsVehicleCriteria"
                  label="I confirm that my vehicle meets all the criteria listed above."
                  watch={watch}
                  control={control}
                  trigger={trigger}
                  error={errors.meetsVehicleCriteria ? true : false}
                  color="primary"
                />
              </FormControl>
              <Typography className="fieldError">{errors.meetsVehicleCriteria?.message}</Typography>
            </Box>

            <Divider className={clsx(classes.reponsiveWidth, 'divider mt4')}></Divider>

            <Box className={clsx(classes.actionButton, 'pt1')}>
              <ButtonPrimary type="submit">Continue</ButtonPrimary>
            </Box>
          </form>
        </Grid>
      </Grid>
    </PageTemplate>
  );
};

export default ImportantInfo;
